var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"d-flex justify-center relative",attrs:{"id":"sketchList"}},[_c('div',{staticClass:"sketch-list-content-box"},[_c('img-popup-preview',{attrs:{"img-src":_vm.currentPreviewImg},model:{value:(_vm.imgPopup),callback:function ($$v) {_vm.imgPopup=$$v},expression:"imgPopup"}}),_c('header-tool-bar-old',{attrs:{"value":_vm.filter,"state":"sketch"},on:{"input":function($event){return _vm.filterUpdated($event)},"search-clicked":function($event){return _vm.filterUpdated($event)}}}),_c('v-row',{staticClass:"content-row"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"title"},[_vm._v(" Sketch ")])]),_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"table-wrap"},[_c('v-data-table',{staticClass:"sketch-table",attrs:{"headers":_vm.sketchHeader,"items":_vm.prototypes,"loading":_vm.tableLoading,"options":_vm.options,"items-per-page":_vm.options.itemsPerPage,"server-items-length":_vm.itemsLength,"item-key":"id","dense":"","show-select":"","hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:sort-by":function($event){return _vm.optionsUpdated()},"update:sort-desc":function($event){return _vm.optionsUpdated()}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('table-body-prototype',{attrs:{"items":items,"headers":headers,"is-selected":isSelected,"select":select,"search-text":_vm.filter.search,"prototype-selected-id":_vm.selectedId,"blank-item":"-"},on:{"click:row":function($event){return _vm.expandSketch($event)}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getDate(value))+" ")]}},{key:"item.diffDays",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDiff(item))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","to":("/prototype/sketch/edit-old/" + (item.id))}},[_c('v-list-item-title',[_vm._v("EDIT")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.tryToDeleteSketch(item.id)}}},[_c('v-list-item-title',[_vm._v("DELETE")])],1)],1)],1)]}}],null,true)})]}}]),model:{value:(_vm.sketchSelected),callback:function ($$v) {_vm.sketchSelected=$$v},expression:"sketchSelected"}})],1)]),_c('v-col',{attrs:{"cols":"5"}},[(_vm.prototypeSelected)?_c('div',{staticClass:"detail-wrap pa-0"},[_c('detail-box',{attrs:{"prototype-selected":_vm.prototypeSelected},on:{"img-click":function($event){return _vm.imgPreview($event)},"create-order-click":function($event){return _vm.createOrder(_vm.prototypeSelected.id)}}})],1):_vm._e()]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"7"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.isDisableBtn,"outlined":""},on:{"click":function($event){return _vm.createManyOrders()}}},[_vm._v(" ส่งใบงาน ("+_vm._s(_vm.sketchSelected.length)+") ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.isDisableBtn,"outlined":""},on:{"click":function($event){return _vm.exportPdf()}}},[_vm._v(" Export PDF ")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-pagination',{attrs:{"total-visible":5,"length":_vm.pageCount,"disabled":_vm.tableLoading,"color":"secondary"},on:{"input":function($event){return _vm.pageUpdated($event)}},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }