<template>
  <section
    id="sketchList"
    class="d-flex justify-center relative">
    <div class="sketch-list-content-box">
      <img-popup-preview
        v-model="imgPopup"
        :img-src="currentPreviewImg" />
      <header-tool-bar-old
        :value="filter"
        state="sketch"
        @input="filterUpdated($event)"
        @search-clicked="filterUpdated($event)" />
      <v-row class="content-row">
        <v-col cols="12">
          <h1 class="title">
            Sketch
          </h1>
        </v-col>
        <v-col cols="7">
          <div class="table-wrap">
            <v-data-table
              v-model="sketchSelected"
              :headers="sketchHeader"
              :items="prototypes"
              :loading="tableLoading"
              :options.sync="options"
              :items-per-page="options.itemsPerPage"
              :server-items-length="itemsLength"
              item-key="id"
              class="sketch-table"
              dense
              show-select
              hide-default-footer
              @update:sort-by="optionsUpdated()"
              @update:sort-desc="optionsUpdated()">
              <template #body="{ headers, items, isSelected, select }">
                <table-body-prototype
                  :items="items"
                  :headers="headers"
                  :is-selected="isSelected"
                  :select="select"
                  :search-text="filter.search"
                  :prototype-selected-id="selectedId"
                  blank-item="-"
                  @click:row="expandSketch($event)">
                  <template #[`item.createdAt`]="{ value }">
                    {{ getDate(value) }}
                  </template>
                  <template #[`item.diffDays`]="{ item }">
                    {{ getDiff(item) }}
                  </template>
                  <template #[`item.action`]="{ item }">
                    <v-menu offset-y>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          x-small
                          v-on="on">
                          <v-icon small>
                            mdi-dots-horizontal
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          link
                          :to="`/prototype/sketch/edit-old/${item.id}`">
                          <v-list-item-title>EDIT</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          link
                          @click="tryToDeleteSketch(item.id)">
                          <v-list-item-title>DELETE</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </table-body-prototype>
              </template>
            </v-data-table>
          </div>
        </v-col>
        <v-col cols="5">
          <div
            v-if="prototypeSelected"
            class="detail-wrap pa-0">
            <!-- <sketch-detail-box
              :sketch="prototypeSelected.sketch"
              @create-order-click="createOrder(prototypeSelected.id)"
              @img-click="imgPreview($event)" /> -->
            <detail-box
              :prototype-selected="prototypeSelected"
              @img-click="imgPreview($event)"
              @create-order-click="createOrder(prototypeSelected.id)" />
          </div>
        </v-col>
        <v-col
          cols="7"
          class="d-flex justify-center">
          <v-btn
            :disabled="isDisableBtn"
            outlined
            class="mx-2"
            @click="createManyOrders()">
            ส่งใบงาน ({{ sketchSelected.length }})
          </v-btn>
          <v-btn
            :disabled="isDisableBtn"
            outlined
            class="mx-2"
            @click="exportPdf()">
            Export PDF
          </v-btn>
          <!-- <v-btn
            :disabled="isDisableBtn"
            outlined
            class="mx-2"
            @click="exportCsv()">
            Export CSV
          </v-btn> -->
        </v-col>
        <v-col cols="5">
          <v-pagination
            v-model="options.page"
            :total-visible="5"
            :length="pageCount"
            :disabled="tableLoading"
            color="secondary"
            @input="pageUpdated($event)" />
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import SketchProviderOld from '@/resources/SketchProviderOld'
import PrototypeOrderProviderOld from '@/resources/PrototypeOrderProviderOld'
import HeaderToolBarOld from '../components/HeaderToolBarOld.vue'
import ImgPopupPreview from '../components/ImgModalPreview.vue'
import TableBodyPrototype from '../components/TableBodyPrototype.vue'
import DetailBox from '../components/DetailBox.vue'
// import SketchDetailBox from '../components/SketchDetailBox.vue'

const SketchService = new SketchProviderOld()
const PrototypeOrderService = new PrototypeOrderProviderOld()

export default {
  components: {
    HeaderToolBarOld,
    ImgPopupPreview,
    TableBodyPrototype,
    DetailBox
    // SketchDetailBox
  },
  data () {
    return {
      imgPopup: false,
      currentPreviewImg: '',
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 15
      },
      pageCount: 0,
      itemsLength: 0,
      currentPage: 1,
      prototypeSelected: null,
      sketchSelected: [],
      sketchHeader: [
        {
          text: 'Sketch ID',
          sortable: false,
          align: 'center',
          value: 'sketch.sketchId'
        },
        {
          text: 'Collection',
          sortable: false,
          align: 'center',
          value: 'sketch.collectionName'
        },
        {
          text: 'Designer',
          sortable: false,
          align: 'center',
          value: 'sketch.designer.name'
        },
        {
          text: 'Merchandiser',
          sortable: false,
          align: 'center',
          value: 'sketch.merchandiser.name'
        },
        {
          text: 'Factory Name',
          sortable: false,
          align: 'center',
          value: 'sketch.factory.name'
        },
        {
          text: 'Classification',
          sortable: false,
          align: 'center',
          value: 'sketch.classification'
        },
        {
          text: 'Date',
          sortable: false,
          align: 'center',
          value: 'createdAt'
        },
        {
          text: 'Days',
          sortable: false,
          align: 'center',
          value: 'diffDays'
        },
        {
          text: '',
          sortable: false,
          align: 'center',
          value: 'action'
        }
      ],
      prototypes: [],
      tableLoading: false,
      searchTimeout: null
    }
  },
  computed: {
    ...mapGetters({
      prototypesState: 'Prototypes/prototypes'
    }),
    isDisableBtn () {
      return this.sketchSelected.length === 0
    },
    filter () {
      const { query } = this.$route
      this.setPage(query?.page || 1)
      return {
        search: query?.search || '',
        designer: query?.designer || '',
        merchandiser: query?.merchandiser || '',
        factory: query?.factory || '',
        collection: query?.collection || '',
        itemsPerPage: query?.itemsPerPage || 15,
        state: 'sketch'
      }
    },
    selectedId () {
      return this.prototypeSelected?.id || null
    }
  },
  created () {
    this.getSketches()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setPrototypes: 'Prototypes/setPrototypes'
    }),
    isNewSchema (item) {
      return 'fabrics' in item.sketch
    },
    setPage (page) {
      this.options.page = Number(page)
    },
    getDate (value) {
      if (value !== '-') {
        return this.$dayjs(value).format('DD/MM/YYYY')
      }
      return value
    },
    getDiff (item) {
      if (item.sketch?.createdAt) {
        return this.$dayjs().diff(this.$dayjs(item.sketch.createdAt), 'day') + 1
      }
      return '-'
    },
    expandSketch ({ item }) {
      this.prototypeSelected = item
    },
    filterUpdated (filters) {
      this.options.itemsPerPage = filters.itemsPerPage
      this.getDataFromFilters({ ...filters, page: 1 })
    },
    pageUpdated (page) {
      this.getDataFromFilters({ ...this.filter, page })
    },
    getDataFromFilters (filters) {
      this.$router.push({
        name: this.$route.name,
        query: { ...filters }
      }).catch(() => {})
      this.getSketches(this.options)
    },
    optionsUpdated () {
      this.getSketches()
    },
    async getSketches (options = this.options, filter = this.filter) {
      try {
        this.tableLoading = true
        const { data } = await SketchService.getManySketches({ ...options, ...filter })
        this.itemsLength = data.total
        this.pageCount = data.pages
        this.prototypes = data.results
        if (this.prototypes.length > 0) {
          this.prototypeSelected = this.prototypes[0]
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.tableLoading = false
      }
    },
    async searchClicked (filter) {
      this.filter = filter
      this.getSketches()
    },
    tryToDeleteSketch (id) {
      this.setModal({
        value: true,
        title: 'DeleteSketch',
        message: 'Do you want to delete this sketch ?',
        confirmText: 'Delete',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.deleteSketch(id)
      })
    },
    async deleteSketch (id) {
      try {
        this.tableLoading = true

        await SketchService.deleteSketch(id)
        this.setSnackbar({
          value: true,
          message: 'Sketch has been deleted.',
          type: 'success'
        })
        this.getSketches()
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.tableLoading = false
      }
    },
    async createOrder (id) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'LOADING...'
        })

        await PrototypeOrderService.createOrder([id])

        this.setSnackbar({
          value: true,
          message: 'Sketch has been changed to order state.',
          type: 'success'
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.$router.push({ name: 'OrderFormListOld' })
      }
    },
    async createManyOrders () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'LOADING...'
        })

        const ids = this.sketchSelected.map((sketch) => sketch.id)

        await PrototypeOrderService.createOrder(ids)

        this.setSnackbar({
          value: true,
          message: 'Sketches has been changed to orders',
          type: 'success'
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.$router.push({ name: 'OrderFormListOld' })
      }
    },
    imgPreview (src) {
      this.currentPreviewImg = src
      this.imgPopup = true
    },
    exportPdf () {
      this.setPrototypes(this.sketchSelected)
      window.open(this.$router.resolve({ name: 'ExportSketch' }).href, '_blank')
    },
    exportCsv () {
      if (this.sketchSelected.length > 0) {
        const preExport = this.sketchSelected.map((sketch) => ({
          'Sketch ID': sketch?.sketchId || '-',
          'Category': sketch?.category?.name || '-',
          'Collection': sketch?.collectionName || '-',
          'Designer': sketch?.designer?.name || '-',
          'Merchandiser': sketch?.merchandiser?.name || '-',
          'Factory': sketch?.factory?.name || '-',
          'Classification': sketch?.classification || '-',
          'Date': this.$dayjs(sketch?.createdAt).format('YYYY-MM-DD') || '-',
          'Note': sketch?.note || '-',
          'Sizing Detail': this.preExportSizingDetail(sketch?.sizeDetail)
        }))

        const options = {
          filename: `SKETCHES(${this.$dayjs().format('YYYY-MM-DDTHH-mm')})`,
          showLabels: true,
          useKeysAsHeaders: true
        }
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(preExport)
      }
    },
    preExportSizingDetail (sizeDetail) {
      const result = sizeDetail.map((detail) => (
        detail.options.map((option) => `${detail.size}(${option.key})=${option.value}`)
      ))

      return result.toString()
    }
  }
}
</script>

<style scope>
.v-data-table--dense.sketch-table > .v-data-table__wrapper > table > tbody > tr > td {
  cursor: pointer;
}

.sketch-table .sketch-table-col {
  height: 32px !important;
  cursor: pointer;
}

.sketch-table .sketch-table-header {
  text-align: center;
  font-weight: bold;
  color: black !important;
}

.sketch-table.sketch-table-row {
  cursor: pointer;
}

.sketch-list-content-box {
  position: relative;
  padding: 15px 15px;
  /* background-color: #fff; */
  width: 100%;
  /* max-width: 1375px; */
}

.content-row {
  position: relative;
  padding: 10px 0;
  background-color: #fff;
}

.table-wrap {
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  height: fit-content;
}

.detail-wrap {
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  height: 532px;
  overflow-y: scroll;
}

.action-col {
  color: rgb(83, 83, 240);
  cursor: pointer;
}

.field-label {
  font-weight: bold;
}

/* .sketch-image {
  margin: 4px 12px 12px;
  height: 120px;
} */

.v-data-table.sketch-table {
  height: 100%
}

.sketch-table .v-data-table__wrapper {
  height: 100%
}
</style>
